import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UserService } from './user.service';
import { LocalStorageService } from 'angular-2-local-storage';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor( private router: Router,
               public localStorageService: LocalStorageService,
               private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.currentUser != null) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
